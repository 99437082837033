<template>
  <svg viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.97027 7.3123C9.02274 7.3123 9.87594 6.48668 9.87594 5.46823C9.87594 4.44977 9.02274 3.62415 7.97027 3.62415C6.9178 3.62415 6.06461 4.44977 6.06461 5.46823C6.06461 6.48668 6.9178 7.3123 7.97027 7.3123Z" fill="currentColor"/>
    <path d="M15.0879 5.31131C14.5276 3.90872 13.5548 2.69576 12.2898 1.82235C11.0249 0.948932 9.52313 0.453344 7.97026 0.396835C6.41739 0.453344 4.91566 0.948932 3.65069 1.82235C2.38572 2.69576 1.41294 3.90872 0.852601 5.31131C0.814758 5.4126 0.814758 5.52351 0.852601 5.6248C1.41294 7.02739 2.38572 8.24034 3.65069 9.11376C4.91566 9.98718 6.41739 10.4828 7.97026 10.5393C9.52313 10.4828 11.0249 9.98718 12.2898 9.11376C13.5548 8.24034 14.5276 7.02739 15.0879 5.6248C15.1258 5.52351 15.1258 5.4126 15.0879 5.31131ZM7.97026 8.46468C7.35779 8.46468 6.75907 8.28893 6.24982 7.95966C5.74057 7.63039 5.34366 7.16238 5.10928 6.61481C4.8749 6.06725 4.81357 5.46473 4.93306 4.88344C5.05254 4.30215 5.34748 3.7682 5.78056 3.34912C6.21364 2.93003 6.76542 2.64463 7.36612 2.529C7.96683 2.41338 8.58947 2.47272 9.15532 2.69953C9.72117 2.92634 10.2048 3.31042 10.5451 3.80322C10.8853 4.29601 11.067 4.87538 11.067 5.46805C11.0657 6.26244 10.739 7.02393 10.1586 7.58564C9.5781 8.14736 8.79117 8.46346 7.97026 8.46468Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconEye'
}
</script>
